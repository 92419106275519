import './App.css';
import 'antd/dist/antd.css';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import AppContent from './AppContent';
import firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyCb3GaTT0YG0WzWXExvLXWyJP8HCG3PdEc",
  authDomain: "virtualanniversary-471c6.firebaseapp.com",
  projectId: "virtualanniversary-471c6",
  storageBucket: "virtualanniversary-471c6.appspot.com",
  messagingSenderId: "259584434803",
  appId: "1:259584434803:web:c514178139145246440006"
};

firebase.initializeApp(firebaseConfig);

const history = createBrowserHistory();

const App = (): JSX.Element => {
  return (
    <div className="App">
      <Router history={history}>
        <AppContent />
      </Router>
    </div>
  );
}

export default App;
