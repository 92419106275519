export type AnniversaryConfig = {
  year: string;
  date: string;
  name: string;
};

const anniversaries: { [year: string]: Array<AnniversaryConfig> } = {
    '2021': [
        {
            year: '2021',
            date: '2-14',
            name: '情人节',
        },
        {
            year: '2021',
            date: '4-27',
            name: '小美女生日',
        },
        {
            year: '2021',
            date: '12-9',
            name: '在一起2000天',
        },
    ],
};

export default anniversaries;
