import React, { Suspense, useCallback, useMemo, useRef, useState } from 'react';
import Navigation from './Navigation.react';
import styled from 'styled-components';
import FlexLayout from './lib/FlexLayout';
import useCurrentAnniversary from './hooks/useCurrentAnniversary';
import { Card } from 'antd';
import { Button, Input } from 'antd';
import { createHash } from 'crypto';
import { Typography } from 'antd';
const { Text } = Typography;

const Display = styled.div`
  flex: 1;
  height: 100%;
`;

const passwords = [
    '79a219fc999f263e21cb1fc12de9307caddd69fc',
    '6a093a2bad14bc7906039ca281c94813094e8048',
    'b91eb94518914ef91ca8f07f9d48977a07ccf90c',
    '7906574f94251ffad775cffa54935a8800373538',
    'a3007fdbfb83bbaa9ad7071206c8da2c16da189a',
    'c40dfba8dd04fbbd3d319a757b04959b5db0b78f',
    'fd088c7befa54510458581b5259763044b95de31',
    'fbdae30e778312feb49f6ee426061cae4febd381',
];

enum VerificationState {
    Initial,
    Success,
    Fail,
}

const AppContent = () => {
    const { year, date } = useCurrentAnniversary();
    const Content = useMemo(
        () => React.lazy(() => import(`./${year}/${date}/Content`)),
        [date, year]
    );
    const password = localStorage.getItem('password');

    const [verificationState, setVerificationState] = useState(
        password && passwords.includes(password)
            ? VerificationState.Success
            : VerificationState.Initial
    );
    const inputRef = useRef<any>(null);

    const confirmPass = useCallback(() => {
        const name = inputRef?.current?.state?.value ?? '';
        const password = createHash('sha1').update(name).digest('hex');
        setVerificationState(
            passwords.includes(password)
                ? VerificationState.Success
                : VerificationState.Fail
        );
        localStorage.setItem('password', password);
    }, []);

    const logout = () => {
        setVerificationState(VerificationState.Initial);
        localStorage.removeItem('password');
    };

    return <>{
        verificationState === VerificationState.Success ?
            <FlexLayout>
                <Navigation logout={logout} />
                <Display style={{ height: '100vh' }} >
                    <Suspense fallback={<div>Loading...</div>}>
                        <Content />
                    </Suspense>
                </Display>
            </FlexLayout> :
            <Card title="你是谁？" style={{
                top: 0, left: 0, bottom: 0, right: 0,
                position: 'absolute'
            }}>
                <FlexLayout direction='vertical' align='center'>
                    <Input ref={inputRef} style={{ width: 300 }} placeholder="你的名字" />
                    {verificationState === VerificationState.Fail && <Text type="danger">对不起，我不认识你</Text>}
                    <Button type="primary" onClick={confirmPass}>确认</Button>
                </FlexLayout>
            </Card>
    }</>
}

export default AppContent;