import { Button, Menu } from 'antd';
import { StarOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useMemo } from 'react';
import Item from 'antd/lib/list/Item';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import anniversaries from './configs/anniversaries';
import useCurrentAnniversary from './hooks/useCurrentAnniversary';

const { SubMenu } = Menu;

const Title = styled.h2`
  padding-left: 16px;
  padding-top: 16px;
`

const NavMenu = styled(Menu)`
  height: 100vh;
`

type Props = { logout: () => void };

const Navigation = ({ logout }: Props): JSX.Element => {
  const history = useHistory();

  const onSelectAnniversary = useCallback(
    (year: string, date: string) =>
      () => history.push(`/${year}/${date}`)
    ,
    [history],
  )

  const menuItems = useMemo(() => Object.entries(anniversaries).map(
    ([year, annivers]) => {
      const anniversItems = annivers.map(({ date, name }) =>
        <Menu.Item key={`/${year}/${date}`} onClick={onSelectAnniversary(year, date)}>{date} {name}</Menu.Item>
      )
      return < SubMenu key={year} icon={< StarOutlined />} title={year} >
        {anniversItems}
      </SubMenu >
    }
  ).reverse(), [onSelectAnniversary]);

  const currentAnniversary = useCurrentAnniversary();
  const { year, date } = currentAnniversary;
  const selection = `/${year}/${date}`;
  useEffect(() => {
    history.push(selection);
  }, [history, selection])

  return (
    <NavMenu
      style={{ width: 200, position: 'relative' }}
      defaultSelectedKeys={[selection]}
      defaultOpenKeys={[year]}
      mode="inline"
    >
      <Item>
        <Title>我们的纪念日！！</Title>
      </Item>
      {menuItems}
      <Button onClick={logout} style={{ position: 'absolute', bottom: 10, width: '90%', right: '5%' }} danger>我要走了</Button>
    </NavMenu>)
}

export default Navigation;