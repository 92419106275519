import { useLocation } from 'react-router-dom';
import anniversaries, { AnniversaryConfig } from '../configs/anniversaries';

const useCurrentAnniversary = (): AnniversaryConfig => {
  const availablePaths = Object.values(anniversaries)
    .flat()
    .map(({ year, date }) => `/${year}/${date}`)
    .sort()
    .reverse();
  const defaultSelection = availablePaths[0];
  const location = useLocation();
  const pathname = location.pathname ?? '';
  const selection = availablePaths.includes(pathname)
    ? pathname
    : defaultSelection;
  const [, year, date] = selection.split('/');
  return anniversaries[year].filter(
    (anniversary) => anniversary['date'] === date
  )[0];
};

export default useCurrentAnniversary;
